/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle, Button, SeparateLine, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--60 pt--50" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36 w--900 ls--001 lh--12" style={{"maxWidth":""}} content={"<span style=\"color: rgb(0, 0, 0);\">Tiskneme vizitky, plakáty<br>a mnoho jiných reklamních materiálů.</span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":800}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-11cyzwg bg--top pb--30 pt--30" name={"uvod-2"} style={{"backgroundColor":"var(--color-variable-1)"}} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/54/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/54/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/54/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/54/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/54/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/54/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/54/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/54/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--80 pt--80" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":640,"backgroundColor":"rgb(11, 178, 255)"}}>
              
              <Text className="text-box fs--36 text-box--invert lh--16" content={"Tiskneme v profesionální kvalitě"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"informace"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26 w--600 ls--001 lh--12" content={"<span style=\"color: rgb(0, 0, 0);\">Pár důvodu proč tisknout u nás</span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":800}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-4.svg"} svg={false} style={{"maxWidth":90}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--26 w--900 ls--001 lh--12 mt--10" content={"<span style=\"color: rgb(0, 0, 0);\">Certifikované tiskárny</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-6.svg"} svg={false} style={{"maxWidth":90}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--26 w--900 ls--001 lh--12 mt--10" content={"<span style=\"color: rgb(0, 0, 0);\">Cenová kalkulace na míru</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-2.svg"} svg={false} style={{"maxWidth":90}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--26 w--900 ls--001 lh--12 mt--10" content={"<span style=\"color: rgb(0, 0, 0);\">Odolnost barev proti UV</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-3.svg"} svg={false} style={{"maxWidth":90}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--26 w--900 ls--001 lh--12 mt--10" content={"<span style=\"color: rgb(0, 0, 0);\">Expresní tisk na počkání</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-m3qi1l pb--40 pt--40" name={"polozky-1"} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/3215/5f61f01f4f304ab8a167bc2343fe09c1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/3215/5f61f01f4f304ab8a167bc2343fe09c1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/3215/5f61f01f4f304ab8a167bc2343fe09c1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/3215/5f61f01f4f304ab8a167bc2343fe09c1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/3215/5f61f01f4f304ab8a167bc2343fe09c1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/3215/5f61f01f4f304ab8a167bc2343fe09c1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/3215/5f61f01f4f304ab8a167bc2343fe09c1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/3215/5f61f01f4f304ab8a167bc2343fe09c1_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--1 mb--30 mt--30" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--26 w--900 ls--001 lh--12" content={"<span style=\"color: rgb(0, 0, 0);\">Tisk vizitek již od 2kč/ks</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" style={{"maxWidth":400}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape2 fs--16 w--600 ls--001" href={"#kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-mqdw9x pb--40 pt--40" name={"polozky-2"} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/3215/bbd7caad6f31479db0bb822fdeab313b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/3215/bbd7caad6f31479db0bb822fdeab313b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/3215/bbd7caad6f31479db0bb822fdeab313b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/3215/bbd7caad6f31479db0bb822fdeab313b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/3215/bbd7caad6f31479db0bb822fdeab313b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/3215/bbd7caad6f31479db0bb822fdeab313b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/3215/bbd7caad6f31479db0bb822fdeab313b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/3215/bbd7caad6f31479db0bb822fdeab313b_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--1 mb--30 mt--30" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--26 w--900 ls--001 lh--12" content={"<span style=\"color: rgb(0, 0, 0);\">Tisk plakátu na barevný papír</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" style={{"maxWidth":400}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape2 fs--16 w--600 ls--001" href={"#kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-v5pxd3 pb--40 pt--40" name={"polozky-3"} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/3215/e0b9a78f302f469386de8070a50d2c50_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/3215/e0b9a78f302f469386de8070a50d2c50_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/3215/e0b9a78f302f469386de8070a50d2c50_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/3215/e0b9a78f302f469386de8070a50d2c50_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/3215/e0b9a78f302f469386de8070a50d2c50_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/3215/e0b9a78f302f469386de8070a50d2c50_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/3215/e0b9a78f302f469386de8070a50d2c50_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/3215/e0b9a78f302f469386de8070a50d2c50_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --right el--1 mb--30 mt--30" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--26 w--900 ls--001 lh--12" content={"<span style=\"color: rgb(0, 0, 0);\">Potisk reklamních předmětů</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" style={{"maxWidth":400}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape2 fs--16 w--600 ls--001" href={"#kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--40" name={"informace-2"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26 w--600 ls--001 lh--12" content={"<span style=\"color: rgb(0, 0, 0);\">Pár důvodu proč tisknout u nás</span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":800}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--30" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/54/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/54/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/54/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/54/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/54/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/54/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box fs--20 w--900 ls--001 lh--12 mt--16" content={"<span style=\"color: rgb(0, 0, 0);\">Oboustranný potisk plastových vizitky s dlouhou životností</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape2 fs--16 w--600 ls--001" href={"#kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/54/img-2_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/54/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/54/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/54/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/54/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/54/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box fs--20 w--900 ls--001 lh--12 mt--16" content={"<span style=\"color: rgb(0, 0, 0);\">Velkoplošní tisk plakátu více jak 200cm x 300cm</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape2 fs--16 w--600 ls--001" href={"#kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/54/img-3_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/54/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/54/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/54/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/54/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/54/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box fs--20 w--900 ls--001 lh--12 mt--16" content={"<span style=\"color: rgb(0, 0, 0);\">Vysokorychlostní barevný tisk s ořezem materiálu </span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape2 fs--16 w--600 ls--001" href={"#kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50" name={"informace-3"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/54/img-3_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/54/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/54/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/54/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/54/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/54/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":430}}>
              
              <Title className="title-box fs--36 w--600 ls--001 lh--12" content={"<span style=\"color: rgb(0, 0, 0);\">Jestli máte jiné přání, ozvěte se nám. Něco určitě vymyslíme.</span>"}>
              </Title>

              <Text className="text-box fs--16" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pt--02" name={"informace-4"} style={{"backgroundColor":"rgba(247, 247, 247, 1)"}}>
        </SeparateLine>


        <Column className="pb--60 pt--40" name={"kontakt"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--4 mt--30 flex--top" columns={"4"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--36 w--900 ls--001 lh--12 mt--25" content={"<span style=\"color: rgb(0, 0, 0);\">Kontakt</span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":""}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Adresa/adresa-7.svg"} svg={false} style={{"maxWidth":80}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Title className="title-box fs--24 w--600 ls--001 lh--12 mt--10" content={"<span style=\"color: rgb(0, 0, 0);\">Holandská 854/1</span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":""}} content={"Brno, Brno – Město<br>Česká Republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/email-5.svg"} svg={false} style={{"maxWidth":80}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Title className="title-box fs--24 w--600 ls--001 lh--12 mt--10" content={"<span style=\"color: rgb(0, 0, 0);\">info@vase-stranky.com</span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":""}} content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/telefon-9.svg"} svg={false} style={{"maxWidth":80}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Title className="title-box fs--24 w--600 ls--001 lh--12 mt--10" content={"<span style=\"color: rgb(0, 0, 0);\">+420 797 811 2X0<br>+420 797 811 2X0</span>"}>
              </Title>

              <Text className="text-box fs--16" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu --style1 --center pb--12 pl--40 pr--40 pt--30" menu={true} name={"paticka"} style={{"backgroundColor":"var(--color-variable-2)"}}>
          
          <Menu className="--center">
            
            <ColumnWrapper className="menu-logo-box" style={{"maxWidth":330}}>
              
              <Title className="title-box fs--18 w--600 title-box--invert ls--04" content={"Jméno Příjmení"}>
              </Title>

              <Text className="text-box fs--16 w--600 ls--04 lh--16 mt--0 pt--0" content={"<span style=\"color: rgb(208, 2, 27);\">Začněte psát text.\n</span>"}>
              </Text>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--18 w--500 ls--001" href={"#polozky-1"} target={""} content={"Vizitky"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--18 w--500 ls--001" href={"#polozky-2"} target={""} content={"Plakáty"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--18 w--500 ls--001" href={"#polozky-3"} target={""} content={"Reklamní předměty"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--right fs--16 w--500 ls--001 pl--10" href={"#kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--20" name={"paticka-2"} style={{"backgroundColor":"var(--color-variable-2)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--30" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--16" style={{"maxWidth":460}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box fs--20 text-box--invert lh--16" content={"+420 797 811 2X0<br>info@vase-stranky.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}